import logo from './logo.svg';
import './App.css';
import AppContainer from './containers/AppContainer';

function App() {
  return (
    <AppContainer/>
  )
}

export default App;
